<script setup lang="ts">
import { $notificationBus, NotificationSeverity } from '@/components/Notification/notification-plugin';
import { onMounted, onUnmounted, Ref, ref } from 'vue';

type Message = { text: string; severity: NotificationSeverity };

const msgs: Ref<Message[]> = ref([]);
const subscriptions: Ref<Record<'unsubscribe', () => void>[]> = ref([]);

onMounted(() => {
  subscriptions.value.push(
    $notificationBus.subscribe((text: string, severity: NotificationSeverity) => {
      msgs.value.push({ text, severity });
    }),
  );
});

onUnmounted(() => {
  subscriptions.value.forEach((sub) => sub.unsubscribe());
});
</script>

<template>
  <div class="notifications__bar">
    <div
      v-for="(msg, i) in msgs"
      :key="`${msg.text}@${i}`"
      :class="`notifications__msg notifications__msg--${
        msg.severity === NotificationSeverity.INFO
          ? 'info'
          : msg.severity === NotificationSeverity.WARNING
            ? 'warning'
            : 'error'
      }`"
      role="dialog"
    >
      {{ msg.text }}
      <v-btn small color="white" @click="msgs.splice(i, 1)">Schließen</v-btn>
    </div>
  </div>
</template>

<style scoped lang="scss">
.notifications__bar {
  position: absolute;
  top: 20px;
  right: 30px;
  width: 350px;

  display: flex;
  flex-direction: column;
  gap: 10px;
}

.notifications__msg {
  border-radius: 5px;
  box-shadow: 1px 1px 7px 5px #ddd;
  color: white;
  min-height: 50px;

  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.notifications__msg-- {
  &info {
    background-color: #9ebc9f;
  }
  &warning {
    color: #4e4e4e;
    background-color: yellow;
  }
  &error {
    background-color: var(--item-red);
  }
}
</style>
