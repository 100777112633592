import { LOCALE } from '@/types/language-options';

export enum USAGE_LOCATION {
  OBJECTS = 'objects',
  PARAMETERS = 'parameters',
  CONNECTIONS = 'connections',
  CATEGORIES = 'categories',
  FILTERS = 'filters',
}

interface UsageLocations {
  [USAGE_LOCATION.OBJECTS]?: string[];
  [USAGE_LOCATION.PARAMETERS]?: string[];
  [USAGE_LOCATION.CONNECTIONS]?: string[];
  [USAGE_LOCATION.CATEGORIES]?: string[];
  [USAGE_LOCATION.FILTERS]?: string[];
}

interface OtherLanguageDataProps {
  id: string;
  searchContent: string;
  touched?: 'touched';
  excludeFromTranslation?: boolean;
  usedIn?: UsageLocations;
}

export type LanguageData = OtherLanguageDataProps & Partial<Record<LOCALE, string>>;
