<script setup lang="ts">
import TextLocalizationTable from '@/components/Table/TextLocalizationTable.vue';
import { languageOptions, LOCALE, LOCALE_TO_KEY } from '@/types/language-options.js';
import { computed, Ref, ref, watch } from 'vue';
import LocalizationFilters from '@/components/Controls/LocalizationFilters.vue';
import LanguageSelection from '@/components/Controls/LanguageSelection.vue';
import Export from '@/components/Controls/ExportXLF.vue';
import textLocalization from '@/apiConnections/text-localization.js';
import Import from '@/components/Controls/ImportXLF.vue';
import CreateNewLocalization from '@/components/Controls/CreateNewLocalization.vue';
import { LanguageData } from '@/types/language-data';
import deepClone from 'rfdc';
import { Localization, LocalizationServiceResponse } from '@/types/localization';
import AppIDSelect from '@/components/Controls/AppIDSelect.vue';
import { $notificationBus, NotificationSeverity } from '@/components/Notification/notification-plugin';
import { findUsages } from '@/components/Table/find-usages.js';

const excludeEmpty = ref(true);
const excludeExternal = ref(false);
const excludeInternal = ref(true);
const excludeTranslated = ref(true);

const appId: Ref<string | null> = ref(null);

const sourceLocale = ref(languageOptions.find((language) => language.id === LOCALE.GERMAN)!);
const targetLocale = ref(languageOptions.find((language) => language.id === LOCALE.ENGLISH)!);
const sourceLangKey = computed(() => LOCALE_TO_KEY[sourceLocale.value.id]);
const targetLangKey = computed(() => LOCALE_TO_KEY[targetLocale.value.id]);

watch([appId, excludeInternal, excludeExternal, excludeTranslated, excludeEmpty, sourceLocale, targetLocale], () => {
  getTextLocalizationTranslations();
  selected.value = [];
});

const loadingLocalizations = ref(false);
const languageData: Ref<LanguageData[]> = ref([]);
const selected: Ref<LanguageData['id'][]> = ref([]);

async function getTextLocalizationTranslations() {
  if (!appId.value || (excludeExternal.value && excludeInternal.value)) {
    languageData.value = [];
    return;
  }

  loadingLocalizations.value = true;

  const exclusionRule = excludeExternal.value ? true : excludeInternal.value ? false : undefined;
  const translations = await textLocalization
    .getAppId(appId.value, sourceLangKey.value, targetLangKey.value, exclusionRule)
    .catch((error) => {
      $notificationBus.emitNotification(
        `Laden der Einträge fehlgeschlagen - der text-localization-service hat einen Fehler zurückgegeben: ${error}`,
        NotificationSeverity.ERROR,
      );
    });

  if (translations) {
    languageData.value = flattenNestedLocalizations(translations);
    if (appId.value === 'object-pool-data') addUsageData();
  }

  loadingLocalizations.value = false;
}
function isLocalization(data: object): data is Localization {
  // very rudimentary type guard because here data can only be Record<string, string | boolean | Record<...>>
  return Object.values(data).some((value) => typeof value !== 'object');
}
function flattenNestedLocalizations(localizations: LocalizationServiceResponse, parentKey?: `${string}/`) {
  const srcLang = sourceLangKey.value;
  const trgLang = targetLangKey.value;

  const itemArray: LanguageData[] = [];
  for (const [key, value] of Object.entries(localizations)) {
    const itemKey = `${parentKey != null ? parentKey : ''}${key}/` as NonNullable<typeof parentKey>;
    if (!isLocalization(value)) {
      itemArray.push(...flattenNestedLocalizations(value, itemKey));
    } else {
      const showEntry =
        !(excludeTranslated.value && value[trgLang]) &&
        !(
          excludeEmpty.value &&
          ((value[srcLang] === '' && value[trgLang] === '') || (!value[srcLang] && !value[trgLang]))
        );
      if (showEntry) {
        itemArray.push({
          id: itemKey,
          [targetLocale.value.id]: value[trgLang],
          [sourceLocale.value.id]: value[srcLang],
          searchContent: `${itemKey} ${value[srcLang]} ${value[trgLang]}`,
          excludeFromTranslation: !!value.excludeFromTranslation,
        });
      }
    }
  }

  return itemArray;
}

async function addUsageData() {
  const ids = languageData.value.map((item) => item.id.slice(0, -1));
  const usages = await findUsages(...ids);
  for (const lang of languageData.value) {
    const id = lang.id.slice(0, -1);
    const u = usages[id];
    if (u) lang.usedIn = u;
  }
}

async function markChangedAsTouched() {
  const oldLangData = deepClone()(languageData.value);
  await getTextLocalizationTranslations();
  const newLangData = deepClone()(languageData.value);

  const diff = newLangData.filter((newData) => {
    const matched = oldLangData.find((oldData) => newData.id === oldData.id);
    if (matched) {
      return newData[targetLocale.value.id] !== matched[targetLocale.value.id];
    } else {
      return true;
    }
  });
  diff.forEach((el) => {
    const findReplaceLanguageEntry = languageData.value.find((langEntry) => langEntry.id === el.id);
    if (findReplaceLanguageEntry) {
      findReplaceLanguageEntry.touched = 'touched';
    }
  });
}
</script>

<template>
  <div>
    <div class="controls">
      <div class="controls__section">
        <Suspense>
          <AppIDSelect v-model:appId="appId" />
          <template #fallback><v-skeleton-loader class="app-select-shadow" /></template>
        </Suspense>
        <localization-filters
          v-model:appId="appId"
          v-model:excludeTranslated="excludeTranslated"
          v-model:excludeEmpty="excludeEmpty"
          v-model:excludeExternal="excludeExternal"
          v-model:excludeInternal="excludeInternal"
        />
        <v-progress-circular
          v-if="loadingLocalizations"
          class="move-right"
          indeterminate
          color="var(--item-red)"
        ></v-progress-circular>
      </div>
      <div class="controls__section">
        <LanguageSelection v-model:sourceLanguage="sourceLocale" v-model:targetLanguage="targetLocale" />
        <Export
          :app-id="appId"
          :disabled="!appId || !selected.length"
          :selected="selected"
          :source-language-id="sourceLocale.id"
        />
        <Import :disabled="!appId" @file-uploaded="markChangedAsTouched" />
        <CreateNewLocalization
          v-if="appId !== 'object-pool-data'"
          :app-id="appId"
          :disabled="!appId"
          :source-language-key="LOCALE_TO_KEY[sourceLocale.id]"
          :target-language-key="LOCALE_TO_KEY[targetLocale.id]"
          @created-new-item="markChangedAsTouched"
        />
      </div>
    </div>
    <text-localization-table
      v-model:selected="selected"
      v-model:items="languageData"
      :app-id="appId"
      :source-col-header="`${sourceLocale.label} (${sourceLocale.id})`"
      :source-col-key="sourceLocale.id"
      :target-col-header="`${targetLocale.label} (${targetLocale.id})`"
      :target-col-key="targetLocale.id"
      class="text-data-table"
    />
  </div>
</template>

<style lang="scss" scoped>
.controls {
  background-color: var(--menu-bg);
  padding: 15px;

  .move-right {
    margin: -3rem 0 0 auto;
  }

  .controls__section {
    display: flex;
    gap: 40px;
    justify-content: start;
    align-items: center;

    & > * {
      flex-grow: 0;
    }
  }

  .app-select-shadow {
    width: 20rem;
    height: 56px;
    overflow: hidden;
  }
}
</style>
