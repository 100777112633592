import axios, { AxiosRequestConfig } from 'axios';
import { Category, Connection, Filter, Obj, Parameter } from '@/apiConnections/opd-types.js';

export interface QueryParams {
  fields?: string;
}

export class ObjectPoolData {
  private static baseUrl = import.meta.env.VITE_OPD_URL;
  private static readonly resourceUrls = {
    objects: `${this.baseUrl}/objects`,
    parameters: `${this.baseUrl}/parameters`,
    connections: `${this.baseUrl}/connections`,
    filters: `${this.baseUrl}/filters`,
    categories: `${this.baseUrl}/categories`,
  };

  public static async getObjects(params?: QueryParams) {
    const req = { method: 'get', url: `${this.resourceUrls.objects}`, params };
    return (await this.fetchFrom(req)) as Obj[];
  }
  public static async getParameter(id: number, params?: QueryParams) {
    const req = { method: 'get', url: `${this.resourceUrls.parameters}/${id}`, params };
    return (await this.fetchFrom(req)) as Parameter;
  }
  public static async getParameters(params?: QueryParams) {
    const req = { method: 'get', url: `${this.resourceUrls.parameters}`, params };
    return (await this.fetchFrom(req)) as Parameter[];
  }
  public static async getConnections(params?: QueryParams) {
    const req = { method: 'get', url: `${this.resourceUrls.connections}`, params };
    return (await this.fetchFrom(req)) as Connection[];
  }
  public static async getFilters(params?: QueryParams) {
    const req = { method: 'get', url: `${this.resourceUrls.filters}`, params };
    return (await this.fetchFrom(req)) as Filter[];
  }
  public static async getCategories(params?: QueryParams) {
    const req = { method: 'get', url: `${this.resourceUrls.categories}`, params };
    return (await this.fetchFrom(req)) as Category[];
  }

  private static async fetchFrom(arg: AxiosRequestConfig | string) {
    const config = typeof arg !== 'string' ? arg : { method: 'get', url: arg };
    return axios(config).then(({ data }) => data);
  }
}
