export enum NotificationSeverity {
  INFO,
  WARNING,
  ERROR,
}

export const $notificationBus = {
  subscribers: {} as Record<number, (msg: string, severity: NotificationSeverity) => unknown>,

  subscribe(cb: (msg: string, severity: NotificationSeverity) => unknown) {
    const id = Number(String(Math.random()).slice(2));
    this.subscribers[id] = cb;
    return { unsubscribe: () => delete this.subscribers[id] };
  },

  emitNotification(msg: string, severity?: NotificationSeverity) {
    Object.values(this.subscribers).forEach((sub) => {
      sub(msg, severity ? severity : NotificationSeverity.INFO);
    });
  },
};
