import { LanguageData, USAGE_LOCATION } from '@/types/language-data.js';
import { ObjectPoolData } from '@/apiConnections/object-pool-data.js';
import { Category, Connection, Filter, Obj, Parameter } from '@/apiConnections/opd-types.js';

export async function findUsages(...ids: LanguageData['id'][]) {
  const locations = await getPossibleLocations();
  const usages: Record<LanguageData['id'], LanguageData['usedIn']> = {};

  for (const [loc, refs] of locations) {
    const byLangRef = sortByLangRef(refs);
    for (const id of ids) {
      if (!byLangRef[id]) continue;

      const usage = usages[id];
      if (!usage) {
        usages[id] = { [loc]: [...byLangRef[id]] };
      } else if (!usage[loc]) {
        usage[loc] = [...byLangRef[id]];
      } else {
        usage[loc]!.push(...byLangRef[id]);
      }
    }
  }

  return usages;
}

interface AllLocations {
  [USAGE_LOCATION.OBJECTS]: Obj[];
  [USAGE_LOCATION.PARAMETERS]: Parameter[];
  [USAGE_LOCATION.CONNECTIONS]: Connection[];
  [USAGE_LOCATION.FILTERS]: Filter[];
  [USAGE_LOCATION.CATEGORIES]: Category[];
}

async function getPossibleLocations() {
  const requests = [
    ObjectPoolData.getObjects({ fields: 'id,title' }),
    ObjectPoolData.getParameters({ fields: 'id,name,validValues.name' }),
    ObjectPoolData.getConnections({ fields: 'id,name' }),
    ObjectPoolData.getFilters({ fields: 'id,name' }),
    ObjectPoolData.getCategories({ fields: 'id,title,description' }),
  ];

  const [objects, parameters, connections, filters, categories] = await Promise.all(requests);
  return new Map([
    [USAGE_LOCATION.OBJECTS, objects],
    [USAGE_LOCATION.PARAMETERS, parameters],
    [USAGE_LOCATION.CONNECTIONS, connections],
    [USAGE_LOCATION.FILTERS, filters],
    [USAGE_LOCATION.CATEGORIES, categories],
  ]);
}

function sortByLangRef(refs: AllLocations[keyof AllLocations]): Record<LanguageData['id'], Set<string>> {
  const sorted: Record<LanguageData['id'], Set<string>> = {};

  for (const r of refs) {
    const refId = String(r.id);
    if ('name' in r) {
      if (sorted[r.name]) {
        sorted[r.name].add(String(refId));
      } else {
        sorted[r.name] = new Set([refId]);
      }
    }
    if ('validValues' in r) {
      for (const v of r.validValues!) {
        if (sorted[v.name]) {
          sorted[v.name].add(String(refId));
        } else {
          sorted[v.name] = new Set([refId]);
        }
      }
    }
    if ('title' in r) {
      if (sorted[r.title]) {
        sorted[r.title].add(String(refId));
      } else {
        sorted[r.title] = new Set([refId]);
      }
    }
    if ('description' in r) {
      if (sorted[r.description]) {
        sorted[r.description].add(String(refId));
      } else {
        sorted[r.description] = new Set([refId]);
      }
    }
  }

  return sorted;
}
